<template>
    <layout>
        <page-header :title="title" :items="items" />

        <div class="row">
            <div
                v-for="key in keys"
                :key="key.key"
                class="col-sm-12 col-md-6 pb-3"
            >
                <div class="card h-100">
                    <div class="card-body">
                        <div class="card-title">
                            <h4>{{ getTitle(key.key) }}</h4>
                        </div>
                        <div class="d-flex flex-wrap">
                            <div class="form-group col-12">
                                <input
                                    :value="key.value"
                                    type="text"
                                    class="form-control"
                                    disabled
                                />
                            </div>
                            <div class="form-group col-12">
                                <button
                                    class="btn btn-success"
                                    @click="generateNewKey(key.key)"
                                >
                                    Generate new
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import startCase from 'lodash/startCase';
import { mapActions } from 'vuex';
import appConfig from '@src/app.config';

export default {
    page: {
        title: 'Api Keys',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    data() {
        return {
            title: 'Api Keys',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Api Keys',
                    active: true
                }
            ],
            keys: []
        };
    },

    async created() {
        try {
            this.keys = await this.getKeys();
        } catch (error) {
            console.error(error);

            this.$toasterError();
        }
    },

    methods: {
        ...mapActions({
            getKeys: 'apiKeys/index',
            generateKey: 'apiKeys/generateByKey'
        }),

        async generateNewKey(key) {
            try {
                await this.generateKey(key);

                this.keys = await this.getKeys();
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        getTitle(key) {
            return startCase(key);
        }
    }
};
</script>
